<template>
  <div id="energy-consumption" class="col-12">
    <v-row no-gutters id="energy-chart">
      <v-col cols="3" class="main-content usage-trend-controller">
        <div class="wp-select-court-menu">
          <v-text-field
            class="select-court-menu"
            v-model="selectedCourtName"
            readonly
            @click="toggleCourtMenu"
          >
            <template #prepend>
              <span><i id="icon-caret-down" class="fas fa-caret-down"></i></span>
            </template>
          </v-text-field>
        </div>
        <div class="usage-trend-page session-ktc-inside usage-trend-menu-none" id="responsive_Court">
          <div class="row pt-1">
            <div class="col-12">
              <div class="text-center">
                <button
                  block
                  class="btn btn-yard btn-yard-height rounded-0"
                  :class="{
                    'btn-yard-active': !activeCourt,
                  }"
                  style="width: 90%; font-size: 20px;"
                  @click="changeActiveCourt()"
                >
                  <span>KALLANG TENNIS CENTRE</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">INDOOR SHOW COURT</label>
              <div>
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.indoor_show_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="changeActiveCourt(item)"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>

                <!-- Menu item virtual IS4 on Ipad -->
                <button
                    block
                    class="btn btn-yard rounded-0 mgl-8 virtual-menu-is4"
                    :style="{ width: width_btn_court + 'px' }"
                  >
                    <span>IS4</span>
                  </button>
                  <!-- Menu item virtual IS4 on Ipad -->
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">INDOOR PRACTICE COURT</label>
              <div>
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.indoor_practice_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="changeActiveCourt(item)"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">OUTDOOR TENNIS COURT</label>
              <div class="row m-0">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[0].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[0])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[0].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[1].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[1])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[1].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[2].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[2])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[2].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[3].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[3])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[3].name
                  }}</span>
                </button>
              </div>
              <div class="row m-0 mt-2">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[4].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[4])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[4].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[5].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[5])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[5].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[6].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[6])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[6].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[7].id ==
                        activeCourt.id,
                  }"
                  @click="
                    changeActiveCourt(courts_data.outdoor_tennis_group[7])
                  "
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[7].name
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">JUNIOR</label>
              <div>
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.junior"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="changeActiveCourt(item)"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>

                <!-- Menu item virtual J3 and J4 on Ipad -->
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>J3</span>
                </button>

                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>J4</span>
                </button>
                <!-- Menu item virtual J3 and J4 on Ipad -->
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">ROOFTOP COURT</label>
              <div>
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.rooftop_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="changeActiveCourt(item)"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="main-content">
        <v-row no-gutters class="table-section wp-user-page-title">
          <v-col cols="12" class="user-page-title main-content">
            <span v-if="!activeCourt || !activeCourt.name" style="display: inline-block; margin-top: 12px;">
              Kallang Tennis Centre
            </span>
            <span v-else style="font-size: 32px; margin-bottom: -20px;" class="yard-schedule">
              Court:
              <span class="yard-schedule-current">
                {{ activeCourt.name }}
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters id="group-select">
          <v-col cols="1" md="1" class="text-left pt-2" style="max-width: 80px;">
            <span>PERIOD:</span>
          </v-col>
          <v-col cols="4" md="2" class="wp-select-month">
            <v-menu
              :close-on-content-click="true"
              :nudge-top="30"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="monthYearText"
                  append-icon="fas fa-caret-down"
                  placeholder="Pick Month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="select-month"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="monthYear"
                :max="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                no-title
                type="month"
                @change="changeFilters"
                class="month-picker-table"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters id="group-chart">
          <v-col cols="12" class="main-chart">
            <template v-if="hasDataEnergyLogs">
              <div class="chart-title-top">
                <h1>Energy Consumption (kWh)</h1>
              </div>
              <VueApexCharts
                type="bar"
                class="chart-horizontal-scroll"
                :options="chartOptions"
                :series="[
                  {
                    name: 'Energy Consumption (kWh)',
                    data: energyConsumptionData,
                  },
                ]"
                :width="width_bar"
                :height="height_bar"
                @click="handleChartClick"
              />
              <div class="current-month">
                <span>{{ monthYearText }}</span>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" src="../../assets/css/schedule.scss"></style>
<style lang="scss" src="../../assets/css/energy-consumption.scss"></style>
<script>
import VueApexCharts from "vue-apexcharts";
import { energyService } from "../../services";
import moment from "moment";
import courts_data_default from "@/components/courts_data";
export default {
  components: { VueApexCharts },
  data() {
    return {
      timeOutReload: null,
      monthYear: moment().format("YYYY-MM"),
      monthYearText: moment().format("MMM-YYYY"),
      courts_data: courts_data_default,
      courts: courts_data_default.data,
      groupModes: {},
      width_btn_court: 71,
      activeCourt: null,
      height_bar: 600,
      width_bar: "100%",
      chartOptions: {
        chart: {
          id: "line-chart",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickPlacement: "between",
          crosshairs: {
            fill: {
              gradient: {
                opacityFrom: 0,
                opacityTo: 0,
              }
            }
          },
        },
        yaxis: {
          title: {
            text: "Energy Consumption (kWh)",
            style: {
              color: "#FFFFFF",
              fontSize: '16px',
            },
          },
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: '16px',
            },
          },
        },
        colors: ["#f79646"],
        tooltip: {
          style: {
            fontSize: "18px",
          },
          theme: "dark",
        },
      },
      energyConsumptionData: [],
      dateData: [],
      dateColors: [],
      hasDataEnergyLogs: true,
      selectedCourtName: "KALLANG TENNIS CENTER",
    };
  },
  async created() {
    await this.getListCourts();
  },
  async mounted() {
    await this.reloadLineChart(0);
    this.getDimensionCourtsBar();
    this.getDimensionWidthBar();
    window.addEventListener("resize", this.getDimensionCourtsBar);
    window.addEventListener("resize", this.getDimensionWidthBar);
  },
  methods: {
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts");
      this.courts = this.$store.state.courts.courts;
      this.courts_data = await this.handelGroupCourts(
        this.groupModes,
        this.courts
      );
    },
    toggleCourtMenu() {
      let collapse = document.getElementById("responsive_Court");
      let icon_rotate = document.getElementById("icon-caret-down");
      if(collapse && icon_rotate) {
        collapse.classList.toggle("open-menu");
        icon_rotate.classList.toggle("rotate-caret-down");
      }
    },
    handleChartClick(event, chartContext, config) {
      if(event.type != "touchend") {
        if (config.dataPointIndex !== -1) {
          chartContext.updateOptions({
            states: {
              active: {
                filter: {
                  type: "darken",
                  value: 0.8,
                },
              },
            },
          });
        } else {
          chartContext.updateOptions({
            states: {
              active: {
                filter: {
                  type: "none",
                },
              },
            },
          });
        }
      }
    },
    getDimensionCourtsBar() {
      let responsive_Court = document.getElementById("responsive_Court");
      if (responsive_Court && responsive_Court.offsetWidth) {
        let ratio_width = 360 / 71;
        this.width_btn_court = responsive_Court.offsetWidth / ratio_width;
        if (
          window.innerWidth < 1878 && window.innerWidth > 1000
        ) {
          this.width_btn_court = this.width_btn_court - 8;
        }
      }
      if (window.innerWidth == 3440 && window.innerHeight == 1440) {
        this.height_bar = 1000;
      } 

    },
    getDimensionWidthBar() {
      if (window.innerWidth <= 500) {
        this.width_bar = 750;
      } 
    },
    async changeActiveCourt(court){
      if(court && court !== undefined){
        this.activeCourt = court;
        this.selectedCourtName = "COURT " + this.activeCourt.name;
      }else{
        this.activeCourt = null;
        this.selectedCourtName = "KALLANG TENNIS CENTER";
      }
      await this.changeFilters();
    },
    async changeFilters() {
      await this.reloadLineChart(200);
    },
    async reloadLineChart(time) {
      if (this.timeOutReload !== null) {
        clearTimeout(this.timeOutReload);
      }
      this.timeOutReload = setTimeout(async () => {
        this.monthYearText = moment(this.monthYear).format("MMM-YYYY");
        let data = {
          start_date: moment(this.monthYear)
            .startOf("month")
            .format(this.DATE_FORMAT_SCHEDULE),
          end_date: moment(this.monthYear)
            .endOf("month")
            .format(this.DATE_FORMAT_SCHEDULE),
          group_id: this.activeCourt && this.activeCourt.id ? this.activeCourt.id : "",
        };
        await energyService
          .getListEnergyLogs(data)
          .then((response) => {
            let dataEnergyLogs = response.data.data;
            if (dataEnergyLogs.length <= 0) {
              this.hasDataEnergyLogs = false;
            }
            const dateValues = dataEnergyLogs.map((item) => item.date);
            for (let i = 0; i < dateValues.length; i++) {
              this.dateColors.push("#FFFFFF");
            }
            const energyConsumptionValues = dataEnergyLogs.map(
              (item) => item.accumulative_energy
            );

            this.dateData = dateValues;
            this.energyConsumptionData = energyConsumptionValues;

            // TEST BAR CHART:
            // this.energyConsumptionData = [
            //   20, 30, 50, 60, 10, 25, 11, 15, 35, 16, 34, 34, 43, 20, 30, 50, 60, 10, 70, 11, 15, 35, 16, 34, 34, 43, 16, 34, 34, 43, 93,
            // ];

            let categoriesArr = this.dateData.map((dateString) => {
              let [day, , ] = dateString.split('-');
              return day;
            });

            let tooltipMonth = moment(this.monthYearText, 'MMM-YYYY').format('MMM');

            this.chartOptions = {
              xaxis: {
                categories: categoriesArr,
                labels: {
                  style: {
                    colors: this.dateColors,
                    fontSize: '16px',
                  },
                },
              },
              tooltip: {
                x: {
                  formatter: function(value) {
                    return value + " " + tooltipMonth;
                  }
                },
              },
            };
          })
          .catch((error) => {
            console.log(error);
          });
      }, time);
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getDimensionCourtsBar);
    window.removeEventListener("resize", this.getDimensionWidthBar);
  },
};
</script>
